import httpClient from '../config/http-client';
import enums from '../../enums';

const getClassPlaceTypeSequence = () => {
    return httpClient
        .get('/api/classes/support-data')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const generateClassReport = (id) => {
    return httpClient
        .get(
            `/api/reports/classes/${id}`,
            { responseType: 'blob' }, // !!!
        )
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

// classplace TanBinh = 1
const getAllClassesWithPaging = (
    page = 1,
    pageSize = 10,
    status = '',
    classPlace = 1,
    className = '',
    category = '',
) => {
    let query = `page=${page}&pageSize=${pageSize}`;
    let urlPrefix = '/api/classes/paging';
    if (classPlace) {
        query = query.concat(`&classPlace=${classPlace}`);
    }
    if (className) {
        query = query.concat(`&className=${className}`);
    }
    if (enums.ClassStatus[status]) {
        query = query.concat(`&status=${enums.ClassStatus[status]}`);
    }
    if (category) {
        query = query.concat(`&category=${category}`);
    }

    if (category === '1vs1') {
        urlPrefix = '/api/classes/1vs1/paging';
    }
    return httpClient
        .get(`${urlPrefix}?${query}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAllClassTypes = () => {
    return httpClient
        .get('/api/class-types')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAllClassPlaces = () => {
    return httpClient
        .get('/api/class-places')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createNewClass = (entity) => {
    return httpClient
        .post('/api/classes', entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createNew1vs1Class = (entity) => {
    return httpClient
        .post('/api/classes/1vs1', entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAllClasses = () => {
    return httpClient
        .get('/api/classes')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getSpecificClassByClassId = (classId) => {
    return httpClient
        .get(`/api/classes/${classId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getMoreInfoByClassId = (classId) => {
    return httpClient
        .get(`/api/classes/${classId}/detail`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateSpecificClassById = (classId, entity) => {
    return httpClient
        .put(`/api/classes/${classId}`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const deleteOneClass = (classId) => {
    return httpClient
        .delete(`/api/classes/${classId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const recoverOneClass = (classId) => {
    return httpClient
        .put(`/api/classes/recover/${classId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

//lesson
const getAllLessonByClassById = (classId) => {
    return httpClient
        .get(`/api/classes/${classId}/lessons`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createLessonOfClass = (classId, lesson) => {
    return httpClient
        .post(`/api/classes/${classId}/lessons`, lesson)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getLessonDetail = (classId, lessonId) => {
    return httpClient
        .get(`/api/classes/${classId}/lessons/${lessonId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateLessonDetail = (classId, lessonId, entity) => {
    return httpClient
        .put(`/api/classes/${classId}/lessons/${lessonId}`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};


const deleteLesson = (classId, lessonId) => {
    return httpClient
        .delete(`/api/classes/${classId}/lessons/${lessonId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

//assignment

const getAllAssignmentsByClassId = (classId) => {
    return httpClient
        .get(`/api/assignments/classes/${classId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAssignmentDetail = (assignmentId) => {
    return httpClient
        .get(`/api/assignments/${assignmentId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createNewAssignment = (value) => {
    return httpClient
        .post(`/api/assignments`, value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateNewAssignment = (assignmentId, value) => {
    return httpClient
        .put(`/api/assignments/${assignmentId}`, value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const deleteAssignment = (assignmentId, value) => {
    return httpClient
        .delete(`/api/assignments/${assignmentId}`, value)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

// Attendance business
const getAllAttendancesInClass = (classId) => {
    return httpClient
        .get(`/api/classes/${classId}/attendances`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAllAttendancesInLessonClass = (classId, lessonId) => {
    return httpClient
        .get(`/api/classes/${classId}/lessons/${lessonId}/attendances`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateAttendanceMultipleRecordsWithStatus = (classId, lessonId, entity) => {
    return httpClient
        .put(`/api/classes/${classId}/lessons/${lessonId}/attendances/status`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

// Attendance business
const getAllExamsInClass = (classId) => {
    return httpClient
        .get(`/api/classes/${classId}/exams`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAllClassTypes,
    getAllClassPlaces,
    createNewClass,
    createNew1vs1Class,
    getAllClasses,
    getAllClassesWithPaging,
    getSpecificClassByClassId,
    getMoreInfoByClassId,
    updateSpecificClassById,
    deleteOneClass,
    recoverOneClass,
    getClassPlaceTypeSequence,
    //lesson
    getAllLessonByClassById,
    createLessonOfClass,
    getLessonDetail,
    updateLessonDetail,
    deleteLesson,
    //assignment
    getAllAssignmentsByClassId,
    createNewAssignment,
    getAssignmentDetail,
    updateNewAssignment,
    deleteAssignment,
    /// attendance
    getAllAttendancesInClass,
    getAllAttendancesInLessonClass,
    updateAttendanceMultipleRecordsWithStatus,
    // report
    generateClassReport,
    // exams,
    getAllExamsInClass,
};
