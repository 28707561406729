import actionTypes from '../action-types';
import apiMethods from '../../http-client/api-methods';
import { createActionCreators } from './utilities';
import _const from '../../assets/const';

const setAllInformation = (values) =>
    createActionCreators(actionTypes.currentUser.SET_ALL_INFORMATION, values);

const setPermissions = (values) =>
    createActionCreators(actionTypes.currentUser.SET_PERMISSIONS, values);

const setUserRole = (value) =>
    createActionCreators(actionTypes.currentUser.SET_INFO_BY_ROLE, value);

const requestGetCurrentUser = () =>
    createActionCreators(actionTypes.currentUser.REQUEST_GET_CURRENT_USER);

const requestGetCurrentUserFulfilled = () =>
    createActionCreators(actionTypes.currentUser.REQUEST_GET_CURRENT_USER_FULFILLED);

const requestGetCurrentUserRejected = () =>
    createActionCreators(actionTypes.currentUser.REQUEST_GET_CURRENT_USER_REJECTED);

const getCurrentUser = () => {
    return (dispatch) => {
        dispatch(requestGetCurrentUser());
        return apiMethods.currentUser
            .getCurrentUser()
            .then((result) => {
                dispatch(requestGetCurrentUserFulfilled());
                const { basicInfo, infoByRole } = result.data.data;
                if (basicInfo && infoByRole) {
                    switch (basicInfo.role) {
                        case _const.Role.Admin: {
                            dispatch(
                                setPermissions({
                                    classes: 'CRU',
                                    assignments: 'CRU',
                                    lessons: 'CRU',
                                    enrollments: 'CRU',
                                    attendances: 'CRU',
                                    payslips: 'CRU',
                                }),
                            );
                            break;
                        }
                        case _const.Role.Employee: {
                            dispatch(
                                setPermissions({
                                    classes: 'CRU',
                                    assignments: 'CRU',
                                    lessons: 'CRUD',
                                    enrollments: 'CRU',
                                    attendances: 'CRU',
                                    payslips: 'R',
                                }),
                            );
                            break;
                        }
                        default:
                            dispatch(
                                setPermissions({
                                    classes: 'R',
                                    assignments: 'R',
                                    lessons: 'CRU',
                                    enrollments: 'R',
                                    attendances: 'CRU',
                                    payslips: 'R',
                                }),
                            );
                    }
                    dispatch(setAllInformation(basicInfo));
                    dispatch(setUserRole(infoByRole));
                }
            })
            .catch((err) => {
                dispatch(requestGetCurrentUserRejected());
            });
    };
};

export default {
    getCurrentUser,
};
